// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  classification: {
    root: path(ROOTS_DASHBOARD, '/classification'),
    classifier: path(ROOTS_DASHBOARD, '/classification/classifier'),
    eventClassifierOptions: path(ROOTS_DASHBOARD, '/classification/eco'),
    // eventPropertyClassifierOptions: path(ROOTS_DASHBOARD, '/classification/epco'),
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
  },
  auditLogs: {
    root: path(ROOTS_DASHBOARD, '/auditlogs'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
