// routes
import Router from './routes';

// Contexts
import useAppData from './hooks/useAppData';
// theme
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

import LoadingScreen from './components/LoadingScreen';

// ----------------------------------------------------------------------

if (localStorage.getItem('env') === null) {
  localStorage.setItem('env', 'mainnet');
}

export default function App() {
  const { loading } = useAppData();

  // useEffect(() => {}, [loading])
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              {!loading ? (
                <>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                </>
              ) : (
                <LoadingScreen isDashboard={false} />
              )}
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
