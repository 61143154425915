import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
let healthUrl;
let dashboardUrl;
let localEnv;

if (localStorage.getItem('env')) {
  localEnv = localStorage.getItem('env');
}

if (localEnv === 'testnet') {
  dashboardUrl = process.env.REACT_APP_TESTNET_ADMINURL;
} else {
  dashboardUrl = process.env.REACT_APP_MAINNET_ADMINURL;
}

const initialState = {
  dashboardAPIUrl: dashboardUrl,
};

const slice = createSlice({
  name: 'enviroment',
  initialState,
  reducers: {
    switchMode(state, action) {
      state = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode } = slice.actions;
