import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import useAuth from '../hooks/useAuth';
import axios from '../utils/API';

const initialState = {
  events: [],

  eventClassifications: [],

  eventClassifierOptions: [],
  loading: false,
  users: [],
  companies: [],
  eventOptions: [],
  auditLogs: {
    auditLogs: [],
    currentPage: 1,
    totalPages: 1,
    pagedLogs: {},
  },
  retrievingLogs: false,
  retrievingEventData: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    return { ...state, ...action.payload };
  },
  SET_EVENTS: (state, action) => {
    return { ...state, ...action.payload };
  },
  SET_ECO: (state, action) => {
    return { ...state, eventClassifierOptions: action.payload };
  },
  SET_CLASSIFICATIONS: (state, action) => {
    return { ...state, eventClassifications: action.payload };
  },
  SET_EVENT_OPTIONS: (state, action) => {
    return { ...state, eventOptions: action.payload };
  },
  SET_AUDIT_LOGS: (state, action) => {
    return { ...state, auditLogs: action.payload, retrievingLogs: false };
  },
  SET_GETTING_LOGS: (state, action) => {
    return { ...state, retrievingLogs: true };
  },
  GETTING_EVENT_DATA: (state, action) => {
    return { ...state, ...action.payload };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AppDataContext = createContext({
  ...initialState,
});

const AppDataProvider = ({ children }) => {
  const { dashboardAPIUrl } = useSelector((state) => state.enviroment);
  const { user, isAuthenticated, isInitialized } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (user) {
      getAllEvents();
      getAllClassifications();
      getAllEventClassifierOptions();
    }
  }, [user]);

  const updateEventClassifier = (eventClassifier) => {
    dispatch({
      type: 'UPDATE_ECO',
      payload: eventClassifier,
    });
  };

  const getAllEvents = async () => {
    dispatch({
      type: 'GETTING_EVENT_DATA',
      payload: {
        retrievingEventData: true,
      },
    });
    const { data } = await axios.get(`${dashboardAPIUrl}/api/events`);

    dispatch({
      type: 'SET_EVENTS',
      payload: {
        events: data,
        retrievingEventData: false,
      },
    });

    dispatch({
      type: 'SET_EVENT_OPTIONS',
      payload: {
        events: data
          .reduce((prev, current) => [...prev, { event: current.event, id: current.id }], [])
          .sort((e1, e2) => {
            if (e1.event < e2.event) return -1;
            if (e1.event > e2.event) return 1;
            return 0;
          }),
      },
    });
  };

  const getAllAuditLogs = async ({
    startDate,
    endDate,
    projectId,
    companyId,
    userId,
    page,
    env = window.localStorage.getItem('env') === 'mainnet' ? 'MainNet' : 'TestNet',
    clearResults = false,
  }) => {
    dispatch({
      type: 'SET_GETTING_LOGS',
    });

    const { data } = await axios.get(`${dashboardAPIUrl}/api/auditlogs`, {
      params: {
        startDate,
        endDate,
        projectId,
        companyId,
        userId,
        page,
        env,
      },
    });

    let newAuditLogs;

    if (clearResults) {
      newAuditLogs = {
        auditLogs: data.auditLogs,
        pagedLogs: { [`${data.currentPage}`]: data.auditLogs },
        currentPage: data.currentPage,
        totalPages: data.totalPages,
      };
    } else {
      newAuditLogs = {
        ...state.auditLogs,
        pagedLogs: { ...state.auditLogs.pagedLogs, [data.currentPage]: data.auditLogs },
        auditLogs: [...state.auditLogs.auditLogs, ...data.auditLogs],
        currentPage: data.currentPage,
        totalPages: data.totalPages,
      };
    }

    dispatch({
      type: 'SET_AUDIT_LOGS',
      payload: newAuditLogs,
    });
  };

  const getAllClassifications = async () => {
    const { data } = await axios.get(`${dashboardAPIUrl}/api/eventclassifications`);

    dispatch({
      type: 'SET_CLASSIFICATIONS',
      payload: data,
    });
  };

  const getAllEventClassifierOptions = async () => {
    const { data } = await axios.get(`${dashboardAPIUrl}/api/eventclassifications/options`);

    data.sort((class1, class2) => {
      if (class1.name < class2.name) return -1;
      if (class1.name > class2.name) return 1;
      return 0;
    });
    dispatch({
      type: 'SET_ECO',
      payload: data,
    });
  };

  const deleteClassification = async (id) => {
    const res = await axios.delete(`${dashboardAPIUrl}/api/eventclassifications/${id}`);

    getAllEvents();
    getAllClassifications();
  };

  const updateEventClassifierOption = async (ecoData) => {
    const payload = {
      ...ecoData,
      propertyOptions: ecoData.propertyOptions.map((opt) => {
        if (typeof opt.id === 'number') {
          return { name: opt.name };
        }
        return opt;
      }),
    };
    const res = await axios.put(`${dashboardAPIUrl}/api/eventclassifications/options/${ecoData.id}`, payload);

    getAllClassifications();
    getAllEventClassifierOptions();
  };

  const createNewClassificationOption = async (ecoData) => {
    const payload = {
      name: ecoData.name,
      properties: ecoData.propertyOptions,
    };

    const res = await axios.post(`${dashboardAPIUrl}/api/eventclassifications/options`, payload);

    getAllEventClassifierOptions();
  };

  const addNewClassification = async (classification) => {
    const payload = {
      eventId: classification.event.id,
      optionId: classification.eventClassification.id,
      properties: classification.properties.filter((prop) => prop.optionId !== null),
      links: [],
    };

    const res = await axios.post(`${dashboardAPIUrl}/api/eventclassifications`, payload);

    getAllEventClassifierOptions();
    getAllClassifications();
    getAllEvents();
  };

  const updateClassification = async (classification) => {
    const payload = {
      id: classification.id,
      eventId: classification.event.id,
      optionId: classification.eventClassification.id,
      properties: classification.properties
        .filter((prop) => prop.optionId !== null)
        .map(({ id, propertyId, optionId, ...rest }) => ({
          id,
          propertyId,
          optionId,
        })),
      links: classification.links.map(({ id, event, ...rest }) => {
        if (typeof id === 'number') {
          return { ...rest };
        }

        return { id, ...rest };
      }),
    };

    const res = await axios.put(`${dashboardAPIUrl}/api/eventclassifications/${classification.id}`, payload);

    getAllClassifications();
  };

  return (
    <AppDataContext.Provider
      value={{
        ...state,
        updateEventClassifierOption,
        createNewClassificationOption,
        updateEventClassifier,
        addNewClassification,
        updateClassification,
        deleteClassification,
        getAllAuditLogs,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
