import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  projects: <SettingsIcon />,
  events: <EventNoteIcon />,
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'App',
    items: [
      {
        title: 'Events Classification',
        path: PATH_DASHBOARD.classification.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'Classifier', path: PATH_DASHBOARD.classification.classifier },
          { title: 'Event Classifier Options', path: PATH_DASHBOARD.classification.eventClassifierOptions },
          // {
          //   title: 'Event Property Classifier Options',
          //   path: PATH_DASHBOARD.classification.eventPropertyClassifierOptions,
          // },
        ],
      },
      {
        title: 'Audit Logs',
        path: PATH_DASHBOARD.auditLogs.root,
        icon: ICONS.events,
      },
      // {
      //   title: 'Companies',
      //   path: PATH_DASHBOARD.classification.eventPropertyClassifierOptions,
      //   icon: ICONS.events,
      // },
    ],
  },
];

export default sidebarConfig;
