import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { element: <Home />, index: true },
        // { path: 'projects', element: <Projects /> },

        {
          path: 'classification',
          children: [
            { element: <Navigate to="/classification/classifier" replace />, index: true },
            { path: 'classifier', element: <Classifier /> },
            { path: 'eco', element: <EventClassifierOptions /> },
          ],
        },
        { path: 'auditlogs', element: <AuditLogsPage /> },
        { path: 'users', element: <UserManagement /> },

        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '*',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// Dashboard
const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));

const Classifier = Loadable(lazy(() => import('../pages/Classifier')));
const EventClassifierOptions = Loadable(lazy(() => import('../pages/EventClassifierOptions')));
const UserManagement = Loadable(lazy(() => import('../pages/UserManagement')));
const AuditLogsPage = Loadable(lazy(() => import('../pages/AuditLogs')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
